(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@groupelacentrale/cbm-user-management-sdk"), require("@groupelacentrale/contact-hooks"), require("@groupelacentrale/lc-ui"), require("@groupelacentrale/libs-logging"), require("axios"), require("prop-types"), require("react"), require("react-dom"), require("react-dom/client"), require("react/jsx-runtime"));
	else if(typeof define === 'function' && define.amd)
		define(["@groupelacentrale/cbm-user-management-sdk", "@groupelacentrale/contact-hooks", "@groupelacentrale/lc-ui", "@groupelacentrale/libs-logging", "axios", "prop-types", "react", "react-dom", "react-dom/client", "react/jsx-runtime"], factory);
	else if(typeof exports === 'object')
		exports["delivery"] = factory(require("@groupelacentrale/cbm-user-management-sdk"), require("@groupelacentrale/contact-hooks"), require("@groupelacentrale/lc-ui"), require("@groupelacentrale/libs-logging"), require("axios"), require("prop-types"), require("react"), require("react-dom"), require("react-dom/client"), require("react/jsx-runtime"));
	else
		root["delivery"] = factory(root["@groupelacentrale/cbm-user-management-sdk"], root["@groupelacentrale/contact-hooks"], root["@groupelacentrale/lc-ui"], root["@groupelacentrale/libs-logging"], root["axios"], root["prop-types"], root["react"], root["react-dom"], root["react-dom/client"], root["react/jsx-runtime"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9814__, __WEBPACK_EXTERNAL_MODULE__6297__, __WEBPACK_EXTERNAL_MODULE__6480__, __WEBPACK_EXTERNAL_MODULE__9322__, __WEBPACK_EXTERNAL_MODULE__3300__, __WEBPACK_EXTERNAL_MODULE__5099__, __WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__715__, __WEBPACK_EXTERNAL_MODULE__5276__) => {
return 